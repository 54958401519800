<template>
  <nav id="adminNav">
    <ul class="mainMenu gnb">
      <li class="dashLogo">
        <a href="/">
          <img src="@/assets/images/reviewmind/logo.png" class="logo1" alt="로고" />
        </a>
      </li>
      <li>
        <router-link to="/admin/users">
          <i class="material-icons">person_add</i>
          회원 관리
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/admin/dashboard">
          <i class="material-icons">dashboard</i>
          대시보드 관리
        </router-link>
      </li>-->
      <li>
        <!-- <router-link to="/admin/sales"> -->
        <router-link to="/admin/payments">
          <i class="material-icons">attach_money</i>
          결제 관리
        </router-link>
      </li>
      <li>
        <router-link to="/admin/demoList">
          <i class="material-icons">list_alt</i>
          데모 관리
        </router-link>
      </li>
      <li>
        <router-link to="/admin/qna">
          <i class="material-icons">support_agent</i>
          문의사항 관리
        </router-link>
      </li>
      <li>
        <router-link to @click.native="logout">
          <i class="material-icons">logout</i>
          로그아웃
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
// import axios from "axios"
import { mapState, mapActions } from "vuex";
import { fetchUserInfo, newAlarm } from "@/api/member";
export default {
  name: "Navbar",

  data() {
    return {
      icon: "menu",
      menu: "메뉴보기",
      displayCenterModal: false,
      drawer: false,
      currentComponent: null,
      modalSize: "",
      visibleNav: false,
      popUp: false,
      alarmPopUp: false,
      serviceFlag: false,
      planYn: "",
      service: "",
      menuInfo: [],
      settingsInfo: [],
      messageListInfo: [],
      newMsg: "",
      username: null
    };
  },
  computed: {
    ...mapState(["userInfo", "isLogin", "category"])
  },
  mounted() {
    this.newMsgCount();
    this.userInfomation();
  },
  methods: {
    ...mapActions(["LOGOUT"]),
    userInfomation() {
      fetchUserInfo().then(res => {
        this.username = res.data.data.username;
        this.point = res.data.data.point;
      });
    },
    move(name) {
      this.$router.push({ name: name });
      this.closeMenu();
    },
    newMsgCount() {
      newAlarm().then(res => {
        if (res.status == 200) {
          this.newMsg = res.data.alarmCount;
        } else {
          alert(res.data.message);
        }
      });
    },
    // clickMsg() {
    //   clickAlarm().then(res => {
    //     if (res.status == 200) {
    //       this.messageListInfo = res.data.messageInfo;
    //       this.newMsgCount();
    //     } else {
    //       alert(res.data.message);
    //     }
    //   });
    // },

    // handleLog() {
    //   if (this.isLogin == true) {
    //     let result = confirm("로그아웃 하시겠습니까?");
    //     if (result) {
    //       if (this.userInfo.type == "KAKAO") {
    //         if (!window.Kakao.Auth.getAccessToken()) {
    //           return alert("토큰 값 없음");
    //         } else {
    //           window.Kakao.Auth.logout(() => {
    //             this.$store.dispatch("LOGOUT");
    //           });
    //         }
    //       } else if (this.userInfo.type == "GOOGLE") {
    //         // window.gapi.auth.getToken();
    //         let auth2 = window.gapi.auth2.getAuthInstance();

    //         auth2.signOut().then(() => {
    //           this.$store.dispatch("LOGOUT");
    //         });
    //       } else {
    //         this.$store.dispatch("LOGOUT");
    //       }
    //     }
    //   } else {
    //     this.$router.push({ name: "login" });
    //   }
    //   this.closeMenu();
    // },
    logout() {
      let result = confirm("로그아웃 하시겠습니까?");
      if (result == false) {
        return;
      }
      this.$store.dispatch("LOGOUT");
    },

    closeModal() {
      this.displayCenterModal = false;
      this.drawer = false;
    },
    handleMove(type) {
      if (type == "1") {
        this.$router.push("/product/productList?category=1");
      }
    },

    closeMenu() {
      // window.document.getElementById("nav").style.display = "block";
      window.document.getElementById("nav").classList.remove("nav__open");
    }
  }
};
</script>
