<template>
  <div class="admin-bg">
    <adminNavbar />
    <div class="admin-body">
      <router-view></router-view>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import adminNavbar from "@/components/admin/adminNavbar";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";

export default {
  mixins: [setNavbar, setFooter],
  components: { adminNavbar },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  }
};
</script>
